<template>
 <div>
  <ExpertStatisticsTemplate
   :periodSelector="periodSelector"
   @timeSelector="selectedtime = $event"
   :elements="extensions"
   :clearTimeSelector="clearTimeSelector"
   @selector="
    $emit('update:selector', $event),
     $emit('setSelector', $event),
     $store.dispatch('pbxPeriodSelected', $event)
   "
   @setPeriod="(...args) => setPeriod(...args)"
   :startDate="startDate"
   :endDate="endDate"
   @activeBanner="$emit('activeBanner')"
   @successMsg="$emit('successMsg', $event)"
   @success="$emit('success', $event)"
   :urlType="urlType"
   @setLoading="$emit('setLoading', $event)"
  >
   <template v-slot:selector>
    <PbxDashboardSelector
     :key="pbxElements"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     :selectHostname="selectHostname"
     :urlType="urlType"
     @queueName="selectedQueue = $event"
     :buttonSelected="buttonSelected"
     :elements="selectedElement"
     @timeSelector="selectedtime = $event"
     @concatenate="concatenateData = $event"
     :groupDatesQueueReport="groupData"
     :concatenateData="concatenateData"
     :pbxElements="pbxElements"
    />
   </template>
   <template v-slot:content>
    <PbxHeader
     v-model:search="search"
     :periodSelector="period"
     :parseDateRange="parseDateRange"
     @select-hostname="selectHostname = $event"
     :selectedQueue="selectedQueue"
     :parseTimeRange="parseTimeRange"
     :timeSelected="selectedtime"
     :buttonSelected="buttonSelected"
     @activeBanner="(activeBanner = true), (key = !key)"
     @successMsg="successMsg = $event"
     @success="isSuccess = $event"
     urlType="did"
    />
    <div class="flex gap-10 py-2">
     <SwitchGroup as="div" class="flex items-center text-center gap-2">
      <Switch
       v-model="didsOnlyStats"
       :class="[
        didsOnlyStats ? 'bg-teal-600' : 'bg-teal-600',
        'relative flex gap-1 text-center h-7 w-48 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
       ]"
      >
       <span
        v-if="!didsOnlyStats"
        class="text-sm font-bold text-white capitalize px-2 py-0.5"
        >{{ $t("Vue Numéro") }}</span
       >
       <!-- <span class="text-sm text-gray-500">20s</span> -->
       <span
        aria-hidden="true"
        :class="[
         !didsOnlyStats ? 'translate-x-1' : 'translate-x-0 w-30',
         'pointer-events-none h-6 text-center items-center px-4 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
       >
        <SwitchLabel as="span" class="flex cursor-pointer">
         <span
          v-if="!didsOnlyStats"
          class="text-sm font-bold text-gray-700 capitalize"
          >{{ $t("Vue File") }}</span
         >
         <span v-else class="text-sm font-bold text-gray-700 capitalize">{{
          $t("Vue Numéro")
         }}</span>
         <!-- <span class="text-sm text-gray-500">20s</span> -->
        </SwitchLabel>
       </span>
       <span
        v-if="didsOnlyStats"
        class="text-sm font-bold text-white capitalize px-2 py-0.5"
        >{{ $t("Vue File") }}</span
       >
       <!-- <span class="text-sm text-gray-500">20s</span> -->
      </Switch>
     </SwitchGroup>
     <SwitchGroup as="div" class="flex items-center gap-1">
      <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
       <span
        :class="[
         didsOnlyStats ? 'text-gray-500' : 'text-gray-900',
         'text-sm font-medium',
        ]"
        >{{ $t("Afficher les extensions ") }}</span
       >
       <!-- <span class="text-sm text-gray-500">20s</span> -->
      </SwitchLabel>
      <Switch
       v-model="queuesOnlyStats"
       :class="[
        !queuesOnlyStats ? 'bg-teal-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
       ]"
       :disabled="didsOnlyStats"
      >
       <span
        aria-hidden="true"
        :class="[
         !queuesOnlyStats ? 'translate-x-5' : 'translate-x-0',
         'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
       />
      </Switch>
     </SwitchGroup>
     <!-- <SwitchGroup as="div" class="flex items-center text-center gap-2">
      <Switch
       v-model="groupData"
       :class="[
        groupData ? 'bg-teal-600' : 'bg-teal-600',
        'relative flex gap-1 text-center h-7 w-60 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
       ]"
      >
       <span
        v-if="!groupData"
        class="text-sm font-bold text-white capitalize px-2 py-0.5"
        >{{ $t("Période totale") }}</span
       >
       <span
        aria-hidden="true"
        :class="[
         !groupData ? 'translate-x-1 ' : 'translate-x-0 w-30',
         'pointer-events-none  h-6 text-center items-center px-4 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
       >
        <SwitchLabel as="span" class="flex cursor-pointer">
         <span
          v-if="!groupData"
          class="text-sm font-bold text-gray-700 capitalize"
          >{{ $t("Jour par jour") }}</span
         >
         <span v-else class="text-sm font-bold text-gray-700 capitalize">{{
          $t("Période totale")
         }}</span>
        </SwitchLabel>
       </span>
       <span
        v-if="groupData"
        class="text-sm font-bold text-white capitalize px-2 py-0.5"
        >{{ $t("Jour par jour") }}</span
       >
      </Switch>
     </SwitchGroup> -->
    </div>
    <div
     class=""
     v-if="
      (queuesOnlyStats ? queueData.length > 0 : extensionData.length > 0) ||
       didData.length > 0
     "
    >
     <div class="overflow-hidden border-gray-200 rounded-md">
      <FullReportTable
       v-if="didsOnlyStats"
       :groupData="groupData"
       :currentSortDir="currentSortDir"
       :tableData="filteredDidData"
       :header="didHeader"
       :body="didDataKey"
       :selectedtime="selectedtime"
       :queuesArray="queuesArray"
       :queuesNameArray="queuesNameArray"
       :extensionsArray="extensionsArray"
       :extensionsNameArray="extensionsNameArray"
       :extensionsNameNumberArray="extensionsNameNumberArray"
       :queuesNameNumberArray="queuesNameNumberArray"
       v-model:searchQueue="searchQueue"
       v-model:searchQueueName="searchQueueName"
       v-model:searchExtension="searchExtension"
       v-model:searchExtensionName="searchExtensionName"
       :period="period"
       :parseDateRange="parseDateRange"
       :queuesOnlyStats="queuesOnlyStats"
       :didsOnlyStats="didsOnlyStats"
       :timeRange="timeRange"
       :timeGap="timeGap"
       :numberOfColumns="numberOfColumns"
       :selectedRangeType="selectedRangeType"
       :concatenateData="concatenateData"
       @sort="sort($event)"
      />
      <FullReportTable
       v-else
       :groupData="groupData"
       :currentSortDir="currentSortDir"
       :tableData="queuesOnlyStats ? filteredQueueData : filteredExtensionData"
       :header="queuesOnlyStats ? queueHeader : extensionHeader"
       :body="queuesOnlyStats ? queueDataKey : extensionDataKey"
       :selectedtime="selectedtime"
       :queuesArray="queuesArray"
       :queuesNameArray="queuesNameArray"
       :extensionsArray="extensionsArray"
       :extensionsNameArray="extensionsNameArray"
       :extensionsNameNumberArray="extensionsNameNumberArray"
       :queuesNameNumberArray="queuesNameNumberArray"
       v-model:searchQueue="searchQueue"
       v-model:searchQueueName="searchQueueName"
       v-model:searchExtension="searchExtension"
       v-model:searchExtensionName="searchExtensionName"
       :period="period"
       :parseDateRange="parseDateRange"
       :queuesOnlyStats="queuesOnlyStats"
       :didsOnlyStats="didsOnlyStats"
       :timeRange="timeRange"
       :timeGap="timeGap"
       :numberOfColumns="numberOfColumns"
       :selectedRangeType="selectedRangeType"
       :concatenateData="concatenateData"
       @sort="sort($event)"
      />
     </div>
    </div>
    <div v-else class="pt-20">
     <div class="text-center">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       class="mx-auto h-12 w-12 text-gray-400"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
      >
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
       />
      </svg>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       {{
        this.pbxElements && this.pbxElements.length > 0
         ? $t("noDataFoundTitle")
         : $t("selectElementFromList")
       }}
      </h3>
     </div>
    </div>
   </template>
  </ExpertStatisticsTemplate>
 </div>

 <!-- <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 /> -->
</template>

<script>
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import ExpertStatisticsTemplate from "./ExpertStatisticsTemplate.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import FullReportTable from "../../components/PBX/FullReportTable.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";
import {
 Switch,
 SwitchGroup,
 SwitchLabel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { ChevronDownIcon, XIcon } from "@heroicons/vue/outline";
import {
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
 InformationCircleIcon,
} from "@heroicons/vue/solid";

const durationMenuItems = [
 { name: "waiting", href: "#", text: "Durées d'attente" },
 //  { name: "talking", href: "#", text: "Durées d'appel" },
];

const keyType = {
 0: "extension",
 4: "queue",
};

const actionType = {
 answered: "answered",
 "1c": "answered_calls",
 transferred: "transferred",
 //  terminated_by_dst: "declined_solicitations",
 //  terminated_by_dst: "declined_calls",
 disuaded: "declined_solicitations",
 abandoned: "abandoned",
 ping: "ping",
 pong: "pong",
 calls: "calls",
 extension_pings: "extension_pings",
 solicited: "solicited",
};

const extensionHeader = [
 { text: "date", info: "", hasInfo: false, key: "day" },
 //  { text: "time", info: "", hasInfo: false },
 { text: "queue", info: "", hasInfo: false },
 { text: "extension", info: "", hasInfo: false },
 {
  text: "calls",
  info: "Total des appels reçus sur une file ou par un utilisateur .",
  hasInfo: true,
  key: "calls",
 },
 {
  text: "solicited",
  info:
   "Nombre de sollicitations reçues par la file d'attente ou l'utilisateur. Un seul appel peut générer plusieurs sollicitations pour un utilisateur. Par exemple, un appel qui arrive dans une file avec une stratégie '1 à 1 dans l`ordre' peut solliciter chaque utilisateurs plusieurs fois à tour de rôle jusqu`à ce que l`appel soit répondu.",
  hasInfo: true,
  key: "solicited",
 },
 {
  text: "declined",
  info:
   "Dans le cas d'une file d'attente, un appel décliné correspond à une dissuasion, c’est-à-dire un renvoi automatique de l'appel vers la destination configurée dans la file (répondeur, numéro externe, autre file…), soit après la durée maximum de l'attente de la file, soit parce qu'aucun agent n'était connecté à la file. Dans le cas d'un utilisateur, un appel décliné est un appel qui n'a pas été répondu ou qui a été refusé par l'agent (en raccrochant ou un faisant un renvoi sur boite vocal).",
  hasInfo: true,
  key: "declined_solicitations",
 },
 {
  text: "abandoned",
  info:
   "L'appelant raccroche sans que l'appel ait été répondu par un agent. Cette statistique ne parait que dans une file.",
  hasInfo: true,
  key: "abandoned",
 },
 { text: "answered", info: "", hasInfo: false, key: "answered" },
 {
  text: "transferred",
  info: "L'appel a été répondu puis transféré vers une file ou une extension.",
  hasInfo: true,
  key: "transferred",
 },
 {
  text: "answered_percentage",
  info:
   "Ce pourcentage représente le rapport du nombre des appels répondus par la ressource au nombre total des appels présentés.",
  hasInfo: true,
  key: "answered_percentage",
 },
 { text: "talking_duration", info: "", hasInfo: false, key: "duration_total" },
 { text: "waiting_duration", info: "", hasInfo: false, key: "waiting_total" },
 {
  text: "ranges",
  info:
   "Nombre d’appels répondus dans le délai considéré. Seuls les appels effectivement répondus par la ressource sont pris en compte.",
  hasInfo: true,
 },
];

const queueHeader = [
 { text: "date", info: "", hasInfo: false, key: "day" },
 //  { text: "time", info: "", hasInfo: false },
 { text: "queue", info: "", hasInfo: false },
 {
  text: "calls",
  info: "Total des appels reçus sur une file ou par un utilisateur .",
  hasInfo: true,
  key: "calls",
 },
 //  {
 //   text: "interne",
 //   info:
 //    "Nombre total d'appels reçus sur une file d'attente ou par un utilisateur.",
 //   hasInfo: true,
 //   key: "appels_internes",
 //  },
 //  {
 //   text: "solicited",
 //   info:
 //    "Nombre de sollicitations reçues par la file d'attente ou l'utilisateur. Un seul appel peut générer plusieurs sollicitations pour un utilisateur. Par exemple, un appel qui arrive dans une file avec une stratégie '1 à 1 dans l`ordre' peut solliciter chaque utilisateurs plusieurs fois à tour de rôle jusqu`à ce que l`appel soit répondu.",
 //   hasInfo: true,
 //   key: "solicited",
 //  },
 {
  text: "declined",
  info:
   "Dans le cas d'une file d'attente, un appel décliné correspond à une dissuasion, c’est-à-dire un renvoi automatique de l'appel vers la destination configurée dans la file (répondeur, numéro externe, autre file…), soit après la durée maximum de l'attente de la file, soit parce qu'aucun agent n'était connecté à la file. Dans le cas d'un utilisateur, un appel décliné est un appel qui n'a pas été répondu ou qui a été refusé par l'agent (en raccrochant ou un faisant un renvoi sur boite vocal).",
  hasInfo: true,
  key: "declined_solicitations",
 },
 {
  text: "abandoned",
  info:
   "L'appelant raccroche sans que l'appel ait été répondu par un agent. Cette statistique ne parait que dans une file.",
  hasInfo: true,
  key: "abandoned",
 },
 {
  text: "answered",
  info: "",
  hasInfo: false,
  key: "answered",
 },
 {
  text: "transferred",
  info: "L'appel a été répondu puis transféré vers une file ou une extension.",
  hasInfo: true,
  key: "transferred",
 },
 {
  text: "answered_percentage",
  info:
   "Ce pourcentage représente le rapport du nombre des appels répondus par la ressource au nombre total des appels présentés.",
  hasInfo: true,
  key: "answered_percentage",
 },
 { text: "talking_duration", info: "", hasInfo: false, key: "duration_total" },
 { text: "waiting_duration", info: "", hasInfo: false, key: "waiting_total" },
 {
  text: "ranges",
  info:
   "Nombre d’appels répondus dans le délai considéré. Seuls les appels effectivement répondus par la ressource sont pris en compte.",
  hasInfo: true,
 },
];

const didHeader = [
 { text: "date", info: "", hasInfo: false, key: "day" },
 //  { text: "time", info: "", hasInfo: false },
 { text: "did", info: "", hasInfo: false },
 {
  text: "calls",
  info: "Total des appels reçus sur une file ou par un utilisateur .",
  hasInfo: true,
  key: "calls",
 },
 //  {
 //   text: "solicited",
 //   info:
 //    "Nombre de sollicitations reçues par la file d'attente ou l'utilisateur. Un seul appel peut générer plusieurs sollicitations pour un utilisateur. Par exemple, un appel qui arrive dans une file avec une stratégie '1 à 1 dans l`ordre' peut solliciter chaque utilisateurs plusieurs fois à tour de rôle jusqu`à ce que l`appel soit répondu.",
 //   hasInfo: true,
 //   key: "solicited",
 //  },
 {
  text: "declined",
  info:
   "Dans le cas d'une file d'attente, un appel décliné correspond à une dissuasion, c’est-à-dire un renvoi automatique de l'appel vers la destination configurée dans la file (répondeur, numéro externe, autre file…), soit après la durée maximum de l'attente de la file, soit parce qu'aucun agent n'était connecté à la file. Dans le cas d'un utilisateur, un appel décliné est un appel qui n'a pas été répondu ou qui a été refusé par l'agent (en raccrochant ou un faisant un renvoi sur boite vocal).",
  hasInfo: true,
  key: "declined_solicitations",
 },
 {
  text: "abandoned",
  info:
   "L'appelant raccroche sans que l'appel ait été répondu par un agent. Cette statistique ne parait que dans une file.",
  hasInfo: true,
  key: "abandoned",
 },
 {
  text: "answered",
  info: "",
  hasInfo: false,
  key: "answered",
 },
 {
  text: "answered_percentage",
  info:
   "Ce pourcentage représente le rapport du nombre des appels répondus par la ressource au nombre total des appels présentés.",
  hasInfo: true,
  key: "answered_percentage",
 },
 { text: "talking_duration", info: "", hasInfo: false, key: "duration_total" },
];

const extensionDataKey = [
 "day",
 "queueNameNumber",
 "elementNameNumber",
 "calls_ext",
 "solicited",
 "declined_solicitations",
 "abandoned",
 "answered",
 "transferred",
 "answered_percentage_ext",
 "talking_duration",
 "waiting_duration",
 "ranges",
];

const didDataKey = [
 "day",
 "did",
 "calls",
 //  "solicited",
 "declined_solicitations",
 "abandoned",
 "answered",
 "answered_percentage_ext",
 "talking_duration",
];

const queueDataKey = [
 "day",
 "queueNameNumber",
 "calls",
 //  "internal_calls",
 //  "solicited",
 "declined_solicitations",
 "abandoned",
 "answered",
 "transferred",
 "answered_percentage_ext",
 "talking_duration",
 "waiting_duration",
 "ranges",
];

export default {
 props: ["presentationKey"],
 components: {
  Loading,
  PbxDashboard,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  ChevronDownIcon,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  InformationCircleIcon,
  XIcon,
  FullReportTable,
  ExpertStatisticsTemplate,
  PbxDashboardSelector,
  PbxHeader,
 },
 data() {
  return {
   urlType: "",
   showInfoText: false,
   currentSortDir: "asc",
   currentSort: "",
   durationMenuItems,
   selectedRangeType: "waiting",
   selectedRangeTypeText: "Durées d'attente",
   timeRange: 20,
   timeGap: 10,
   numberOfColumns: 4,
   groupData: true,
   searchQueue: null,
   searchQueueName: null,
   searchExtension: null,
   searchExtensionName: null,
   queuesNameArray: [],
   queuesArray: [],
   extensionsNameArray: [],
   extensionsNameNumberArray: [],
   queuesNameNumberArray: [],
   extensionsArray: [],
   didHeader,
   didDataKey,
   queueHeader,
   queueDataKey,
   extensionHeader,
   extensionDataKey,
   queuesOnlyStats: true,
   keyType,
   actionType,
   data: {},
   selectedtime: undefined,
   fullPage: true,
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   queueData: [],
   didData: [],
   extensionData: [],
   textMonth: "",
   buttonSelected: "month",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   textMonth: "",
   buttonSelected: "month",
   settedGroupTableData: [],
   settedUngroupedTableData: [],
   queueDataConsolidated: [],
   didDataConsolidated: [],
   concatenateData: false,
   startDate: null,
   endDate: null,
   didsOnlyStats: true,
   pbxMap: null,
   dns: null,
   pbxElements: null,
  };
 },
 methods: {
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getData() {
   try {
    const res = await axios.get(
     `https://xp-stats-201.bluerock.tel/api/v1.2/reports/${this.presentationKey}`
    );
    console.log(res.data);
    return res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    // this.$emit("error404", error.response);
    this.errorHandling(error);
   }
  },
  async separateQueueExtensionData(groupSelected) {
   this.$emit("setLoading", true);
   this.queueData = [];
   this.didData = [];
   this.extensionData = [];
   this.settedGroupTableData = null;
   this.settedUngroupTableData = null;
   this.queueDataConsolidated = [];
   this.extensionDataConsolidated = [];
   this.extensionsNameNumberArray = [];
   this.queuesNameNumberArray = [];
   let getData = await this.getData();
   let dataArray = [];
   if (this.groupData) {
    this.settedGroupTableData = getData?.data;
   } else {
    this.settedUngroupTableData = getData?.data;
   }

   this.data = getData?.data;
   this.pbxMap = getData?.map ? getData.map : null;
   let parameters = getData.parameters;
   this.dns = parameters?.dns?.split(",");
   this.pbxElements = parameters?.dns?.split(",");
   this.startDate = parameters?.start;
   this.endDate = parameters?.end;
   this.urlType =
    parameters.element_type == 4
     ? "queue"
     : parameters.element_type == 0
     ? "extension"
     : "did";

   this.periodSelector = this.formatPeriod(parameters.start, parameters.end);

   this.$store.dispatch("hostName", parameters.host_name);

   if (getData?.pbx_table_config) {
    this.$store.dispatch("pbx3cxTableConfig", getData?.pbx_table_config);
   }

   //  this.$store.dispatch("dataSet", parameters.report_type);

   if (this.didsOnlyStats) {
    dataArray = this.groupData
     ? this.settedGroupTableData?.did
     : this.settedUngroupTableData?.did;
   } else {
    dataArray = this.groupData
     ? this.settedGroupTableData?.queue
     : this.settedUngroupTableData?.queue;
    let elementsNameNumber = dataArray
     .filter(res => res.keyType == 0)
     .map(res => {
      return res.elementNameNumber;
     });
    let uniqElementsNameNumber = [...new Set(elementsNameNumber)];
    this.extensionsNameNumberArray = uniqElementsNameNumber;

    let queuesNameNumber = dataArray
     .filter(res => res.keyType == 4)
     .map(res => {
      return res.elementNameNumber;
     });
    let uniqQueuesNameNumber = [...new Set(queuesNameNumber)];

    this.queuesNameNumberArray = uniqQueuesNameNumber;
   }
   console.log("dataArray", dataArray);
   for (let index = 0; index < dataArray.length; index++) {
    const el = dataArray[index];
    if (el.queue == el.element && el.keyType == 4) {
     this.queueData.push(el);
    }
    if (el.queue == el.element || el.keyType == 0) {
     this.extensionData.push(el);
    }
    if (el.keyType === "did") {
     this.didData.push(el);
    }
   }
   if (this.pbxMap && this.pbxMap["call_queues"] && this.pbxMap["extensions"]) {
    this.selectedElement = this.dns.map(res => {
     return (
      res +
      (this.urlType === "queue"
       ? this.pbxMap["call_queues"][res]
         ? "-" + this.pbxMap["call_queues"][res]["name"]
         : ""
       : this.pbxMap["extensions"][res]
       ? "-" + this.pbxMap["extensions"][res]
       : "")
     );
    });
   }
   this.consolidateDataQueue(this.queueData);
   this.consolidateDataExtension(this.extensionData);
   this.consolidateDataDid(this.didData);
   this.$emit("setLoading", false);
  },
  consolidateDataDid(data) {
   let consolidatedData = [];
   let holder = {};
   let ignoreKeys = [
    "element",
    "elementName",
    "elementNameNumber",
    "keyType",
    "queue",
    "queueName",
    "queueNameNumber",
   ];
   if (data && data.length > 0) {
    data.forEach(res => {
     let keys = Object.keys(res);
     for (let index = 0; index < keys.length; index++) {
      const elementProperty = keys[index];
      if (ignoreKeys.indexOf(elementProperty) < 0) {
       if (holder.hasOwnProperty(elementProperty)) {
        holder[elementProperty] =
         parseInt(holder[elementProperty]) + parseInt(res[elementProperty]);
       } else {
        holder[elementProperty] = parseInt(res[elementProperty]);
       }
      }
     }
    });
   }
   consolidatedData.unshift(holder);
   holder["did"] = "Consolidée";
   this.didDataConsolidated = consolidatedData;
  },
  consolidateDataQueue(data) {
   let consolidatedData = [];
   let holder = {};
   let ignoreKeys = [
    "element",
    "elementName",
    "elementNameNumber",
    "keyType",
    "queue",
    "queueName",
    "queueNameNumber",
   ];
   if (data && data.length > 0) {
    data.forEach(res => {
     let keys = Object.keys(res);
     for (let index = 0; index < keys.length; index++) {
      const elementProperty = keys[index];
      if (ignoreKeys.indexOf(elementProperty) < 0) {
       if (holder.hasOwnProperty(elementProperty)) {
        holder[elementProperty] =
         parseInt(holder[elementProperty]) + parseInt(res[elementProperty]);
       } else {
        holder[elementProperty] = parseInt(res[elementProperty]);
       }
      }
     }
    });
   }
   consolidatedData.unshift(holder);
   holder["queueNameNumber"] = "Consolidée";
   this.queueDataConsolidated = consolidatedData;
  },
  consolidateDataExtension(data) {
   let duplicatedArr = [];
   let extensions = [];
   data.forEach(res => {
    if (res.keyType == 0) {
     extensions.push(res.element);
    }
   });
   let duplicatedElements = extensions.filter(
    (item, index) => extensions.indexOf(item) !== index
   );

   let consolidatedData = [];
   let holder = {};
   let extensionHolder = {};
   let ignoreKeys = [
    "element",
    "elementName",
    "elementNameNumber",
    "keyType",
    "queue",
    "queueName",
    "queueNameNumber",
   ];
   if (data && data.length > 0) {
    data.forEach((res, resIndex) => {
     let keys = Object.keys(res);
     if (res.keyType == 4) {
      holder["queueNameNumber"] = "Consolidée";
      holder["keyType"] = 4;
      for (let index = 0; index < keys.length; index++) {
       const elementProperty = keys[index];
       if (ignoreKeys.indexOf(elementProperty) < 0) {
        if (holder.hasOwnProperty(elementProperty)) {
         holder[elementProperty] =
          parseInt(holder[elementProperty]) + parseInt(res[elementProperty]);
        } else {
         holder[elementProperty] = parseInt(res[elementProperty]);
        }
       }
      }
      if (
       consolidatedData[0] &&
       consolidatedData[0]["keyType"] &&
       consolidatedData[0]["keyType"] != 4
      ) {
       consolidatedData.unshift(holder);
      } else {
       if (consolidatedData.filter(res => res.keyType == 4).length == 0) {
        consolidatedData.push(holder);
       }
      }
     } else {
      if (duplicatedElements.indexOf(res.element) < 0) {
       consolidatedData.push(res);
      } else {
       duplicatedArr.push(res);
      }
      //   extensionHolder = {};
      //   for (let index = 0; index < extensions.length; index++) {
      //    const elementExtension = extensions[index];
      //    if (elementExtension == res.element) {
      //     extensionHolder["queueNameNumber"] = "Consolidée";
      //     extensionHolder["elementNameNumber"] = res.elementNameNumber;
      //     extensionHolder["element"] = res.element;
      //     extensionHolder["keyType"] = res.keyType;
      //     for (let index = 0; index < keys.length; index++) {
      //      const elementProperty = keys[index];
      //      if (ignoreKeys.indexOf(elementProperty) < 0) {
      //       if (extensionHolder.hasOwnProperty(elementProperty)) {
      //        extensionHolder[elementProperty] = parseInt(extensionHolder[elementProperty]) + parseInt(res[elementProperty]);
      //       } else {
      //        extensionHolder[elementProperty] = parseInt(res[elementProperty]);
      //       }
      //      }
      //     }
      //     consolidatedData[index + 1] = extensionHolder;
      //    }
      //   }
     }
    });
    let arr = [];
    let uniqueDuplicatedElements = [...new Set(duplicatedElements)];
    for (
     let elementsIndex = 0;
     elementsIndex < uniqueDuplicatedElements.length;
     elementsIndex++
    ) {
     let obj = {};
     const element = uniqueDuplicatedElements[elementsIndex];
     duplicatedArr.forEach((dup, dupIdx) => {
      let keys = Object.keys(dup);
      if (element == dup.element) {
       obj["queueNameNumber"] = "Consolidée";
       obj["elementNameNumber"] = dup.elementNameNumber;
       obj["element"] = dup.element;
       obj["keyType"] = dup.keyType;
       obj["elementName"] = dup.elementName;
       for (let index = 0; index < keys.length; index++) {
        const elementProperty = keys[index];
        if (ignoreKeys.indexOf(elementProperty) < 0) {
         if (obj.hasOwnProperty(elementProperty)) {
          obj[elementProperty] =
           parseInt(obj[elementProperty]) + parseInt(dup[elementProperty]);
         } else {
          obj[elementProperty] = parseInt(dup[elementProperty]);
         }
        }
       }
      }
     });
     arr[elementsIndex] = obj;
    }
    consolidatedData = [...arr, ...consolidatedData];
   }
   this.extensionDataConsolidated = consolidatedData;
  },
  formatPeriod(start, end) {
   return start.split(" ")[0] + "-" + end.split(" ")[0];
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
  formatTime(time) {
   let formatedTime = time.slice(0, 2) + ":" + time.slice(-2);
   return formatedTime;
  },
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedDataExtension() {
   let extensionData =
    this.concatenateData && this.groupData
     ? this.extensionDataConsolidated.filter(res => res.keyType == 0)
     : this.extensionData.filter(res => res.keyType == 0);
   let queueData =
    this.concatenateData && this.groupData
     ? this.extensionDataConsolidated.filter(res => res.keyType == 4)
     : this.extensionData.filter(res => res.keyType == 4);
   let extensionDataSorted = [];
   let queueDataSorted = [];
   let fullData = [];

   extensionDataSorted = extensionData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else if (toBeSorted === "duration_total_avg") {
     if (
      a["talking_duration"] / a["answered"] <
      b["talking_duration"] / b["answered"]
     )
      return -1 * modifier;
     if (
      a["talking_duration"] / a["answered"] >
      b["talking_duration"] / b["answered"]
     )
      return 1 * modifier;
     if (a["answered"] == 0 || b["answered"] == 0) {
      if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
      if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "duration_total_total") {
     if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
     if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
    } else if (toBeSorted === "waiting_total_avg") {
     if (
      a["waiting_duration"] / a["calls"] <
      b["waiting_duration"] / b["calls"]
     )
      return -1 * modifier;
     if (
      a["waiting_duration"] / a["calls"] >
      b["waiting_duration"] / b["calls"]
     )
      return 1 * modifier;
     if (a["calls"] == 0 || b["calls"] == 0) {
      if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
      if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "waiting_total_total") {
     if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
     if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });

   queueDataSorted = queueData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else if (toBeSorted === "duration_total_avg") {
     if (
      a["talking_duration"] / a["answered"] <
      b["talking_duration"] / b["answered"]
     )
      return -1 * modifier;
     if (
      a["talking_duration"] / a["answered"] >
      b["talking_duration"] / b["answered"]
     )
      return 1 * modifier;
     if (a["answered"] == 0 || b["answered"] == 0) {
      if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
      if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "duration_total_total") {
     if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
     if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
    } else if (toBeSorted === "waiting_total_avg") {
     if (
      a["waiting_duration"] / a["calls"] <
      b["waiting_duration"] / b["calls"]
     )
      return -1 * modifier;
     if (
      a["waiting_duration"] / a["calls"] >
      b["waiting_duration"] / b["calls"]
     )
      return 1 * modifier;
     if (a["calls"] == 0 || b["calls"] == 0) {
      if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
      if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "waiting_total_total") {
     if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
     if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
   fullData = [...queueDataSorted, ...extensionDataSorted];
   return fullData;
  },
  sortedDataQueue() {
   let queueData = this.concatenateData
    ? this.queueDataConsolidated
    : this.queueData;
   return queueData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else if (toBeSorted === "duration_total_avg") {
     if (
      a["talking_duration"] / a["answered"] <
      b["talking_duration"] / b["answered"]
     )
      return -1 * modifier;
     if (
      a["talking_duration"] / a["answered"] >
      b["talking_duration"] / b["answered"]
     )
      return 1 * modifier;
     if (a["answered"] == 0 || b["answered"] == 0) {
      if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
      if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "duration_total_total") {
     if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
     if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
    } else if (toBeSorted === "waiting_total_avg") {
     if (
      a["waiting_duration"] / a["calls"] <
      b["waiting_duration"] / b["calls"]
     )
      return -1 * modifier;
     if (
      a["waiting_duration"] / a["calls"] >
      b["waiting_duration"] / b["calls"]
     )
      return 1 * modifier;
     if (a["calls"] == 0 || b["calls"] == 0) {
      if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
      if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "waiting_total_total") {
     if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
     if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
  sortedDataDid() {
   let didData = this.concatenateData ? this.didDataConsolidated : this.didData;
   return didData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "answered_percentage") {
     if (a["answered"] / a["calls"] < b["answered"] / b["calls"])
      return -1 * modifier;
     if (a["answered"] / a["calls"] > b["answered"] / b["calls"])
      return 1 * modifier;
    } else if (toBeSorted === "duration_total_avg") {
     if (
      a["talking_duration"] / a["answered"] <
      b["talking_duration"] / b["answered"]
     )
      return -1 * modifier;
     if (
      a["talking_duration"] / a["answered"] >
      b["talking_duration"] / b["answered"]
     )
      return 1 * modifier;
     if (a["answered"] == 0 || b["answered"] == 0) {
      if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
      if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "duration_total_total") {
     if (a["talking_duration"] < b["talking_duration"]) return -1 * modifier;
     if (a["talking_duration"] > b["talking_duration"]) return 1 * modifier;
    } else if (toBeSorted === "waiting_total_avg") {
     if (
      a["waiting_duration"] / a["calls"] <
      b["waiting_duration"] / b["calls"]
     )
      return -1 * modifier;
     if (
      a["waiting_duration"] / a["calls"] >
      b["waiting_duration"] / b["calls"]
     )
      return 1 * modifier;
     if (a["calls"] == 0 || b["calls"] == 0) {
      if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
      if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
     }
    } else if (toBeSorted === "waiting_total_total") {
     if (a["waiting_duration"] < b["waiting_duration"]) return -1 * modifier;
     if (a["waiting_duration"] > b["waiting_duration"]) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
 },
 watch: {
  selectedtime: function(val) {
   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   this.separateQueueExtensionData();
   if (flag) this.concatenateData = true;
  },
  period: function(val) {
   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   this.separateQueueExtensionData();
   if (flag) this.concatenateData = true;
  },
  groupData: function(val) {
   let grouped = "grouped";
   this.separateQueueExtensionData(grouped);
  },
  didsOnlyStats: function(val) {
   this.separateQueueExtensionData();
   this.queuesOnlyStats = true;
  },
  searchQueue: function(val) {
   if (val && val.length <= 0) {
    this.searchQueue = null;
   }
  },
  urlType: function(val) {
   this.$store.dispatch("pbxElements", null);
  },
  concatenateData: function(val) {
   if (val) this.groupData = true;
  },
 },
 mounted() {
  this.separateQueueExtensionData();
 },
 computed: {
  filteredDidData() {
   //  let queueData = this.concatenateData
   //   ? this.queueDataConsolidated
   //   : this.queueData;
   return this.sortedDataDid().filter(data => {
    if (this.searchQueue) {
     let flag = false;
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else {
     return data;
    }
   });
  },
  filteredQueueData() {
   //  let queueData = this.concatenateData
   //   ? this.queueDataConsolidated
   //   : this.queueData;
   return this.sortedDataQueue().filter(data => {
    if (this.searchQueue) {
     let flag = false;
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else {
     return data;
    }
   });
  },
  filteredExtensionData() {
   return this.sortedDataExtension().filter(data => {
    if (this.searchQueue && !this.searchExtension) {
     let flag = false;
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flag = true;
      }
     });
     return flag;
    } else if (!this.searchQueue && this.searchExtension) {
     let flag = false;
     this.searchExtension.forEach(element => {
      if (
       element.includes(data.element) &&
       element.includes(data.elementName)
      ) {
       flag = true;
      }
     });
     return flag;
    } else if (this.searchQueue && this.searchExtension) {
     let flag = false;
     let flagQueue = false;
     let flagExtension = false;
     this.searchExtension.forEach(element => {
      if (
       element.includes(data.element) &&
       element.includes(data.elementName)
      ) {
       flagExtension = true;
      }
     });
     this.searchQueue.forEach(queue => {
      if (queue.includes(data.queue) && queue.includes(data.queueName)) {
       flagQueue = true;
      }
     });
     if (flagExtension && flagQueue) {
      flag = true;
     }
     return flag;
    } else {
     return data;
    }
   });
  },
  sortedExtensionData() {
   //  let extensionData = this.concatenateData
   //   ? this.extensionDataConsolidated
   //   : this.extensionData;
   let firstSort = this.sortedDataExtension().sort((a, b) => {
    if (a.keyType == 4) {
     return -1;
    }
   });

   return firstSort.sort((a, b) => {
    if (a.keyType == 4) {
     return parseInt(a.date) - parseInt(b.date);
    }
   });
  },
  ...mapGetters([
   "hostName",
   "hostTimeZone",
   "pbxPeriodSelected",
   "pbxElementType",
   "pbx3cxTableConfig",
  ]),
 },
};
</script>

<style>
.switch-group {
 position: absolute;
 right: 1.3rem;
 top: 7.5rem;
}
.switch-group-no-info {
 position: absolute;
 right: 1.3rem;
 top: 7.5rem;
}
</style>
